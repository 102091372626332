import Img1 from '../images/govtech.png';
import Img2 from '../images/rse.png';
import Img3 from '../images/eventos.png';
import Img4 from '../images/edu.png';
import Img5 from '../images/prog.png';
import Img6 from '../images/spon.png';
import Img7 from '../images/dvtic.png';


const cardData = [
  {
    link: "/govtech",
    foto: Img1,
    external: false
  },
  {
    link: "/rse",
    foto: Img2,
    external: false
  },
  {
    link: "/novedades",
    foto: Img3,
    external: false
  },
  {
    link: "/educacion",
    foto: Img4,
    external: false
  },
  {
    link: "/programas",
    foto: Img5,
    external: false
  },
  {
    link: "/sponsors",
    foto: Img6,
    external: false
  },
  {
    link: "https://dvtic.fundacion360.com.ar/",
    foto: Img7,
    external: true // ✅ Marca como enlace externo
  }
];

export default cardData;