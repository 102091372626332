import React, { useState, useEffect } from 'react';
import '../styles/Inicio.css';
import videoSrc from '../images/bienvenidos.mp4';
import wpIcn from '../images/whatsapp.png';
import CmpsIcn from '../images/logo-campus.png';
import { useNavigate } from 'react-router-dom';
import upArrow from '../images/upArrow.png';
import downArrow from '../images/downArrow.png';
import ImageModal from './ModalImages';
import api from '../services/api';
import dataGov from '../data/CardData.js';

function Inicio() {
  const [modalOpen, setModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data } = await api.get('/images');
        setImages(data);
      } catch (error) {
        console.error('Error al obtener las imágenes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const toggleExpand = () => setExpanded(!expanded);

  // 🔥 Nueva función para manejar redirecciones internas y externas
  const handleRedirect = (item) => {
    if (item.external) {
      window.open(item.link, '_blank', 'noopener,noreferrer'); // Abre en nueva pestaña
    } else {
      navigate(item.link); // Navegación interna
    }
  };

  return (
    <section className="bSection">
      <div className="bLeft" id="bLeft">
        <h1 className="bTittle" id="bTittle">Fundación 360</h1>

        <p className="bText" id="bText">
          La Fundación 360 desarrolla programas que integran a los ciudadanos en el universo de la Economía del Conocimiento, el Gobierno Digital y las Industrias 4.0. Con más de 200 casos de éxito en Empresas, Gobierno y Asociaciones contribuye también a los espacios formativos de recursos humanos y programas de Responsabilidad Social Empresaria, habiendo capacitado a la fecha alrededor de 25.000 alumnos en Competencias Digitales, Robótica y Domótica.
        </p>

        <button onClick={openModal} className="view-images-button">Ver Galería</button>
      </div>

      <div className="bRigth">

	<a href="https://wa.me/message/Q32NSDP5QIQGN1" target="_blank" className="whatsapp-icon" rel="noopener noreferrer">
          <img src={wpIcn} alt="WhatsApp"/>
        </a>

        <video className="bVideo" src={videoSrc} muted autoPlay loop playsInline preload="auto"></video>
        <a href="https://campus.fundacion360.com.ar/?" target="_blank" className="campus-icon" rel="noopener noreferrer">
          <img src={CmpsIcn} alt="CampusFundacion"/>
        </a>
      </div>

      <ImageModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        images={images}
        loading={loading}
      />

        <div
            className={`expand-arrow ${expanded ? 'expanded' : ''}`}
            onClick={toggleExpand}
        >
            {expanded ? (
                <img src={downArrow} alt="Down Arrow" />
            ) : (
                <img src={upArrow} alt="Up Arrow" />
            )}
        </div>


      {/* 🔥 Ajuste en los botones de redirección */}
      <div className="image-buttons-container">
        <div className='container-int'>
        {dataGov.map((item, index) => (
          <img
            key={index}
            src={item.foto}
            alt={`Button ${index}`}
            className={`image-button ${expanded ? 'expanded' : ''}`}
            onClick={() => handleRedirect(item)} // ✅ Redirección corregida
          />
        ))}
        </div>
      </div>
    </section>
  );
}

export default Inicio;